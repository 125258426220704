"use client";

import { Suspense } from "react";
import { graphql, useFragment } from "react-relay";

import PuckRender from "@/website-editor/render";
import { OrganizationActivePageRendererFragment$key } from "@generated/OrganizationActivePageRendererFragment.graphql";

interface Props {
  webPage: OrganizationActivePageRendererFragment$key;
}

const OrganizationActivePageRenderer: React.FC<Props> = ({ webPage }) => {
  const data = useFragment(
    graphql`
      fragment OrganizationActivePageRendererFragment on WebPage {
        content
      }
    `,
    webPage,
  );

  return (
    <Suspense fallback="Loading...">
      <PuckRender data={data.content as any} />
    </Suspense>
  );
};

export default OrganizationActivePageRenderer;
