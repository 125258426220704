/**
 * @generated SignedSource<<e799c8dd973f6cc7b24082714d57f416>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type LandingPageQuery$variables = Record<PropertyKey, never>;
export type LandingPageQuery$data = {
  readonly organization: {
    readonly activeWebPage: {
      readonly " $fragmentSpreads": FragmentRefs<"OrganizationActivePageRendererFragment" | "metadataFragmentOrganizationActiveWebPage">;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"OrganizationContext_organization">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"AdminAuthContext_authState">;
};
export type LandingPageQuery = {
  response: LandingPageQuery$data;
  variables: LandingPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "slug",
    "value": "pi"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "path",
    "value": ""
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "normalizedEmail",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "givenName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "size",
      "value": 360
    }
  ],
  "kind": "ScalarField",
  "name": "image",
  "storageKey": "image(size:360)"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v12 = [
  (v2/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/),
  (v7/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "WebsiteSettings",
    "kind": "LinkedField",
    "name": "websiteSettings",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CustomDomain",
        "kind": "LinkedField",
        "name": "customDomain",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "domain",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "verificationStatus",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v2/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LandingPageQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AdminAuthContext_authState"
      },
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrganizationContext_organization"
          },
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "WebPage",
            "kind": "LinkedField",
            "name": "activeWebPage",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "OrganizationActivePageRendererFragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "metadataFragmentOrganizationActiveWebPage"
              }
            ],
            "storageKey": "activeWebPage(path:\"\")"
          }
        ],
        "storageKey": "organization(slug:\"pi\")"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LandingPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canCreateOrganization",
                "storageKey": null
              },
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationMember",
                "kind": "LinkedField",
                "name": "organizationsAsMember",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": (v12/*: any*/),
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "excludeOrganizationsAsMember",
                    "value": true
                  }
                ],
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organizationsAsInstructor",
                "plural": true,
                "selections": (v12/*: any*/),
                "storageKey": "organizationsAsInstructor(excludeOrganizationsAsMember:true)"
              }
            ],
            "type": "User",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v9/*: any*/),
          (v11/*: any*/),
          (v10/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationMember",
            "kind": "LinkedField",
            "name": "viewerMembership",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationStudent",
            "kind": "LinkedField",
            "name": "viewerStudent",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AudienceGroup",
                "kind": "LinkedField",
                "name": "audienceGroups",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "label",
                    "storageKey": null
                  },
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hidden",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "WebPage",
            "kind": "LinkedField",
            "name": "activeWebPage",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "content",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "seoFields",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": "activeWebPage(path:\"\")"
          }
        ],
        "storageKey": "organization(slug:\"pi\")"
      }
    ]
  },
  "params": {
    "cacheID": "8a4f802550f3378d4f8bcbc67f4dad92",
    "id": null,
    "metadata": {},
    "name": "LandingPageQuery",
    "operationKind": "query",
    "text": "query LandingPageQuery {\n  ...AdminAuthContext_authState\n  organization(slug: \"pi\") {\n    ...OrganizationContext_organization\n    activeWebPage(path: \"\") {\n      ...OrganizationActivePageRendererFragment\n      ...metadataFragmentOrganizationActiveWebPage\n      id\n    }\n    id\n  }\n}\n\nfragment AdminAuthContext_authState on Query {\n  viewer {\n    __typename\n    ... on User {\n      id\n      email\n      normalizedEmail\n      givenName\n      lastName\n      canCreateOrganization\n      image(size: 360)\n      organizationsAsMember {\n        role\n        organization {\n          id\n          slug\n          name\n          status\n          image(size: 360)\n          websiteSettings {\n            customDomain {\n              domain\n              verificationStatus\n            }\n            id\n          }\n        }\n        id\n      }\n      organizationsAsInstructor(excludeOrganizationsAsMember: true) {\n        id\n        slug\n        name\n        status\n        image(size: 360)\n        websiteSettings {\n          customDomain {\n            domain\n            verificationStatus\n          }\n          id\n        }\n      }\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment OrganizationActivePageRendererFragment on WebPage {\n  content\n}\n\nfragment OrganizationContext_organization on Organization {\n  id\n  slug\n  status\n  name\n  image(size: 360)\n  viewerMembership {\n    role\n    id\n  }\n  viewerStudent {\n    id\n    email\n    normalizedEmail\n    givenName\n    lastName\n    image(size: 360)\n    createdAt\n    audienceGroups {\n      id\n      slug\n      label\n      status\n      hidden\n    }\n  }\n}\n\nfragment metadataFragmentOrganizationActiveWebPage on WebPage {\n  seoFields\n}\n"
  }
};
})();

(node as any).hash = "09c8d918a6cf961778614a51b011e767";

export default node;
