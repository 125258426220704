"use client";

import { SerializablePreloadedQuery } from "@/relay/loadSerializableQuery";
import { graphql, usePreloadedQuery, useRelayEnvironment } from "react-relay";
import useSerializablePreloadedQuery from "@/relay/useSerializablePreloadedQuery";
import { notFound } from "next/navigation";
import OrganizationActivePageRenderer from "@/web-pages/components/OrganizationActivePageRenderer";
import React from "react";

import AdminAuthContextProvider from "@/admin/context/AdminAuthContext";
import NavBar from "@/auth/components/NavBar";
import LandingPageQueryNode, {
  LandingPageQuery,
} from "@generated/LandingPageQuery.graphql";
import OrganizationContextProvider from "@/student/context/OrganizationContext";

const LandingPage: React.FC<{
  preloadedQuery: SerializablePreloadedQuery<
    typeof LandingPageQueryNode,
    LandingPageQuery
  >;
}> = ({ preloadedQuery }) => {
  const environment = useRelayEnvironment();

  const queryRef = useSerializablePreloadedQuery(environment, preloadedQuery);

  const data = usePreloadedQuery(
    graphql`
      query LandingPageQuery {
        ...AdminAuthContext_authState
        organization(slug: "pi") {
          ...OrganizationContext_organization
          activeWebPage(path: "") {
            ...OrganizationActivePageRendererFragment
            ...metadataFragmentOrganizationActiveWebPage
          }
        }
      }
    `,
    queryRef,
  );

  if (!data.organization || !data.organization.activeWebPage) {
    notFound();
  }

  return (
    <OrganizationContextProvider organization={data.organization}>
      <AdminAuthContextProvider authState={data}>
        <NavBar />
        <OrganizationActivePageRenderer
          webPage={data.organization.activeWebPage}
        />
      </AdminAuthContextProvider>
    </OrganizationContextProvider>
  );
};

export default LandingPage;
